<template>
    <div v-if="entryValue" class="relative">
      <div :class="{'w-full justify-between flex items-center justify-center gap-[15px] bg-white uppercase text-[13px] text-lightGray font-medium flex-wrap': true, [rounding]: true, [padding]: true, '!pb-0': this.expanded, [border]: true}">
        <div class="absolute left-0 ml-[-50px] w-[50px] h-full leading-[50px] flex items-center justify-center">
          <slot name="icon" :entry="entry"></slot>
        </div>

        <div :class="{[primaryColumnSize]: true, 'max-w-[50%] xl:flex-shrink-0 flex-grow-0 pl-[15px] relative': true, [primaryColumnClass]: true, 'scrolled': isScrolled}" v-if="columns[0]">
          <ui-table-entry-content
              :column="columns[0]"
              v-model="entryValue"
              @expand="expand"
              :expanded="expanded !== ''"
              :class="[columns[0].id, 'sm:max-w-[100%] overflow-auto']"
              :data="data"
              :state="state"
          ></ui-table-entry-content>
        </div>

        <div :class="{[secondaryColumnClass]: true, 'no-scrollbar max-w-min gap-[20px]': true}" v-on:scroll="onScroll" ref="secondary">
          <template v-for="column in columns.slice(1)">
            <ui-table-entry-content
                :column="column"
                v-model="entryValue"
                @expand="expand"
                :class="{'hidden xl:block': !column.mobile, [column.id]: true}"
                :expanded="expanded !== ''"
                :state="state"
                :data="data"></ui-table-entry-content>
          </template>
        </div>

        <div v-if="actions.length || expanded" class="pr-[15px] w-[30px] xl:w-[60px]">
          <ui-table-entry-content
              :column="{component: () => import('@/fabric/ui/UITableEntryActions.vue'), size: 'w-[60px]'}"
              v-model="entryValue"
              @expand="expand"
              :expanded="expanded !== ''"
              v-bind="{actions: actions}"
              :grow="true"
              @hide="onHide"
              :data="data"
              :state="state"
          ></ui-table-entry-content>
        </div>

        <div v-if="expanded" :class="{'w-full': true, 'overflow-hidden': !expanded, [rounding]: true, 'rounded-t-none': true}">
          <component :is="expanded" :entry="entry"></component>
        </div>
      </div>

      <slot name="entry-footer" :entry="entry"></slot>
    </div>
</template>

<script>
import UITableEntryContent from './UITableEntryContent.vue'
import {watch} from "vue";
export default {
    props: {
        columns: Array,
        entry: Object,
        primaryColumnSize: String,

        rounding: String,
        shadow: String,
        border: String,
        padding: String,

        actions: {
            type: Array,
            default: []
        },

      state: {
        type: [Object, Array],
      },

      data: {
          type: Object,
          default: () => ({}),
      },

        tableId: String
    },

    data()
    {
        return {
            selected: false,
            expanded: '',
            entryValue: this.entry,

            primaryColumnClass: 'flex-[1.5] pt-[10px] pb-[10px] pr-[10px] xl:pt-0 xl:pb-0 xl:pr-0',
            secondaryColumnClass: 'flex-shrink flex-grow flex overflow-x-auto xl:overflow-x-visible flex-[2] gap-[20px] xl:flex-1 items-center last:pr-[15px] relative'
        };
    },


    mounted()
    {
        watch(this.$root.table,  (table) =>
        {
            if (this.tableId && table[this.tableId] && typeof table[this.tableId].scroll !== "undefined")
            {
                this.$refs.secondary.scrollLeft = table[this.tableId].scroll;
            }
        });
    },

    computed: {
      isScrolled()
      {
          return this.$root.table && this.tableId && this.$root.table[this.tableId] && this.$root.table[this.tableId].scroll;
      }
    },

    methods: {
      onHide()
      {
        console.log('triggered hide..');
        this.visible = false;
      },

        onScroll(scroll)
        {
            if (this.tableId)
            {
                if (typeof this.$root.table[this.tableId] === "undefined")
                {
                    this.$root.table[this.tableId] = {
                        scroll: scroll.target.scrollLeft
                    }
                }
                else
                {
                    this.$root.table[this.tableId].scroll = scroll.target.scrollLeft;
                }
            }
        },

        expand(component)
        {
            this.expanded = component;
        }
    },

    watch: {
        entry(entry)
        {
            this.entryValue = entry;
        }
    },

    components: {
        'ui-table-entry-content': UITableEntryContent
    }
}
</script>
