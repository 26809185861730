<template>
    <div :class="{'flex flex-col group/table p-[10px] bg-white shadow-md rounded': true, [gap]: true}" v-if="visible">
      <slot name="controls"></slot>

      <StickyElement visibleOnDirection="disabled" @stuck="stuck" ref="sticky" :skipChecks="!sticky">
            <div class="group group-table">
                <div :id="id+'-table-header'" :class="{'justify-between items-center justify-center gap-[15px] uppercase text-[11px] font-medium py-[11px] px-0 select-none mx-auto group-[&.vue-sticky-element--show]:shadow-none flex flex-wrap': true, [rounding]: true, [headerBackground]: true, [headerText]: true, 'pl-[0] xl:pl-[15px]': true}">
                    <div :class="{[primaryColumnSize]: true, 'xl:flex-shrink-0 flex-grow-0 flex-[1.5] max-w-[50%]': true, 'pl-[15px]': true}" v-if="columns_width_defaults[0]">
                        <ui-table-column
                            :column="columns_width_defaults[0]"
                            :active-column="value"
                            @select="toggle_filter(columns_width_defaults[0].id)"></ui-table-column>
                    </div>

                    <div class="flex-shrink gap-[20px] max-w-min flex-grow flex overflow-x-auto xl:overflow-x-visible no-scrollbar flex-[2] gap-[20px] pr-[15px]" ref="secondary">
                        <template v-for="column in columns_width_defaults.slice(1)">
                            <ui-table-column
                                :column="column"
                                :active-column="value"
                                :class="{'hidden xl:block': !column.mobile}"
                                @select="toggle_filter(column.id)"></ui-table-column>
                        </template>
                    </div>

                    <ui-table-column
                        v-if="actions.length"
                        class="h-[20px]"
                        :column="{size: 'w-[60px]'}"
                        :active-column="value"></ui-table-column>
                </div>
            </div>
        </StickyElement>

        <template v-if="entries.length === 0 && !loading" class="text-[#A1ACB8]">
            <div class="px-[15px] py-[10px]">
                No results found.
            </div>
        </template>

        <template v-else>
            <div v-for="entry in entries" :key="entry.id" class="[&:not(:last-child)]:border-b [&:not(:last-child)]:border-[#efefef]">
                <template v-if="groups[entry.id]">
                    <div :class="{'bg-[#f5f5f56e] border-b border-[#F3F2EF] text-semibold': true, [entryPadding]: true, 'pl-[15px]': true}">
                        {{groups[entry.id].name}}
                    </div>
                </template>

                <ui-table-entry
                    :columns="columns_width_defaults"
                    :entry="entry"
                    :primary-column-size="primaryColumnSize"
                    :rounding="rounding"
                    :padding="entryPadding"
                    :border="border"
                    :key="entry.id"
                    :actions="actions"
                    :state="state"
                    :data="data"
                    :table-id="id"
                    :class="typeof entryClasses[entry.id] !== 'undefined' ? entryClasses[entry.id] : ''"
                >
                  <template v-slot:icon="{entry}">
                    <slot name="icon" :entry="entry"></slot>
                  </template>

                  <template v-slot:entry-footer="{entry}">
                    <slot name="entry-footer" :entry="entry"></slot>
                  </template>
                </ui-table-entry>
            </div>

            <ui-table-pagination v-if="paginationEnabled" :class="paginationClass" :results="entries.length" :total="total" :results-per-page="resultsPerPage" v-model="pageValue" :rounding="rounding"></ui-table-pagination>
        </template>

        <div class="flex place-content-between pl-[15px]" v-if="loading || paginationShowStats">
          <div v-if="!loading" class="invisible group-hover/table:visible"></div>

          <span class="float-left" v-if="loading">
              <CSpinner size="sm"/> Loading...
            </span>

          <div v-if="paginationShowStats">
            Showing {{entries.length}} results out of {{total}}
          </div>
        </div>
    </div>
</template>

<script>
import StickyElement from "vue-sticky-element";
import UITableColumn from "./UITableColumn.vue";
import UITableEntry from "./UITableEntry.vue";
import UITablePagination from "./UITablePagination.vue";
import {watch} from "vue";
import {useStorage } from '@vueuse/core';
import {CSpinner} from "@coreui/vue-pro/dist/esm/components/spinner";

export default {
  name: 'UITable',

    props: {
        id: String,

        // id, label, sortable (false), adaptable (false), size, classes
        columns: Array,

        // table entries
        entries: Array,

      state: {
        type: [Object, Array],
        default: () => null
      },

      loading: {
          type: Boolean,
          default: false
      },

        entryClasses: {
          type: Object,
          default: {}
        },

      paginationEnabled: {
        type: Boolean,
        default: true
      },

        primaryColumnSize: String,

        modelValue: String,

        sticky: {
            type: Boolean,
            default: true
        },

        page: 1,
        total: 0,
        resultsPerPage: 10,

        groups: {
          type: Object,
          default: {}
        },

        rounding: {
          type: String,
          default: 'rounded'
        },

      data: {
        type: String,
        default: {},
        required: false
      },

        gap: {
            type: String,
            default: 'gap-[4px]'
        },

        border: {
            type: String,
            default: ''
        },

        headerBackground: {
            type: String,
            default: 'bg-purple'
        },

        headerText: {
            type: String,
            default: 'text-blue'
        },

        entryPadding: {
            type: String,
            default: 'xl:pt-[11px] xl:pb-[11px]'
        },

        paginationShowStats: {
            type: Boolean,
            default: false
        },

        paginationClass: String,

        actions: {
            type: Array,
            default: []
        }
    },

    defaultProps: {
        entries: [],
        primaryColumnSize: "w-[300px]",
        rounding: ""
    },

    computed: {
        entry() {
            return entry
        },
        columns_width_defaults()
        {
            return this.columns.map(column => {
                return this._column_defaults(column);
            }).filter(column => column.visible);
        }
    },

    data()
    {
        let sortDirection, value;

        if (this.modelValue)
        {
            sortDirection = this.modelValue.charAt(0);
            value = this.modelValue.slice(1);
        }

        return {
            value: value,
            sortDirection: sortDirection,
            pageValue: this.page,
            visible: useStorage(this.id+'-table.visible', true),
        }
    },

    mounted()
    {
        watch(this.$root.table,  (table) =>
        {
            if (this.id && table[this.id] && typeof table[this.id].scroll !== "undefined")
            {
                this.$refs.secondary.scrollLeft = table[this.id].scroll;
            }
        });

        window.addEventListener('table-visibility-changed-'+this.id, (event) => {
            this.visible = event.detail.value;
        });
    },

    methods: {
        toggle_filter(filter)
        {
            if (this.value === filter)
            {
                this.sortDirection = this.sortDirection == '+' ? '-' : '+';
            }
            else
            {
                this.value = filter;
                this.sortDirection = '-';
            }

            this.$emit("update:modelValue", this.sortDirection+this.value);
            this.$emit("change");
        },

        stuck(data)
        {
            if (data)
            {
                if (document.getElementById(this.id+'-table-header'))
                {
                    document.getElementById(this.id+'-table-header').style.width = this.$refs.sticky.$el.parentElement.offsetWidth;
                }
            }
            else
            {
                if (document.getElementById(this.id+'-table-header'))
                {
                    document.getElementById(this.id+'-table-header').style.width = '';
                }
            }
        },

        _column_defaults(column)
        {
            if (typeof column.size === 'undefined')
            {
                column.size = '';
            }

            if (typeof column.mobile === 'undefined')
            {
                column.mobile = 1;
            }

            if (typeof column.class === 'undefined')
            {
                column.class = '';
            }

            if (typeof column.adaptable === 'undefined')
            {
                column.adaptable = true;
            }

            if (typeof column.sortable === 'undefined')
            {
                column.sortable = false;
            }

            if (typeof column.size === 'undefined')
            {
                column.size = '';
            }

            if (typeof column.component === 'undefined')
            {
                column.component = '';
            }

            if (typeof column.content === 'undefined')
            {
              column.content = '';
            }

          if (typeof column.visible === 'undefined')
          {
            column.visible = true;
          }

            if (typeof column.content === 'undefined')
            {
                column.content = () =>
                {
                    return '';
                }
            }
            else if (typeof column.content === 'string')
            {
                column.content = () =>
                {
                    return column.content;
                }
            }

            return column;
        }
    },

    watch: {
        modelValue(newValue)
        {
            let sortDirection = this.modelValue.charAt(0);
            let value = this.modelValue.slice(1);

            console.log(sortDirection, value);

            this.value = value;
            this.sortDirection = sortDirection;
        },

        pageValue(page)
        {
            this.pageValue = page;
            this.$emit("paginate", page);
        },

        page(page)
        {
            this.pageValue = page;
        }
    },

    components: {
      CSpinner,
        StickyElement,
        'ui-table-column': UITableColumn,
        'ui-table-entry': UITableEntry,
        'ui-table-pagination': UITablePagination
    },
}
</script>
