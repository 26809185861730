<script setup>
import {onBeforeMount, ref} from 'vue'
import { useColorModes } from '@coreui/vue-pro'

import { useThemeStore } from '@/stores/theme.js'

const { isColorModeSet, setColorMode } = useColorModes(
  'coreui-free-vue-admin-template-theme',
)
const currentTheme = useThemeStore();

onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.href.split('?')[1])
  let theme = urlParams.get('theme')

  if (theme !== null && theme.match(/^[A-Za-z0-9\s]+/)) {
    theme = theme.match(/^[A-Za-z0-9\s]+/)[0]
  }

  if (theme) {
    setColorMode(theme)
    return
  }

  if (isColorModeSet()) {
    return
  }

  setColorMode(currentTheme.theme)
})
</script>

<template>
  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
// We use those styles to show code examples, you should remove them in your application.
@import 'styles/examples';

.resource-invoice-status {
  &.resource-invoice-status-awaiting-payment,
  &.resource-invoice-status-awaiting-bank-payment{
    color: #f9ad20;
  }

  &.resource-invoice-status-paid
  {
    color: #30c130;
  }
}

.resource-holiday_booking-status {
  &.resource-holiday_booking-status-pending-payment {
    color: #f9ad20;
  }

  &.resource-holiday_booking-status-paid,
  &.resource-holiday_booking-status-deposit-paid, {
    color: #30c130;
  }
}
</style>
