<template>
  <CSidebar     class="border-end"
                colorScheme="dark"
                position="fixed"
                :unfoldable="sidebar.unfoldable"
                :visible="sidebar.visible"
                @visible-change="(value) => sidebar.toggleVisible(value)">
    <CSidebarHeader class="border-bottom">
      <CSidebarBrand class="fw-bolder">
        <CLink href="/">
          <CIcon icon="cil-home me-1"/>
        </CLink>

        Snatcher
      </CSidebarBrand>
    </CSidebarHeader>
    <CSidebarNav>
      <template v-for="item in items">
          <template v-if="item.type == 'item'">
            <template v-if="item.attributes.items.length">
              <CNavGroup>
                <template #togglerContent>
                  <CIcon v-if="item.attributes.icon" customClassName="nav-icon" :icon="item.attributes.icon"/>
                  {{item.attributes.name}}
                  <CBadge v-if="item.attributes.badge !== null" class="ms-auto" :color="item.attributes.badgeType">{{item.attributes.badge}}</CBadge>
                </template>
                <CNavItem href="#" v-for="childItem in item.attributes.items" @click="navigate(childItem)">
                  <span class="nav-icon"><span class="nav-icon-bullet"></span></span>
                  {{childItem.attributes.name}}
                  <CBadge v-if="childItem.attributes.badge !== null" class="ms-auto" :color="childItem.attributes.badgeType">{{childItem.attributes.badge}}</CBadge>
                </CNavItem>
              </CNavGroup>
            </template>

            <template v-else>
              <CNavItem href="#" @click="navigate(item)">
                <CIcon v-if="item.attributes.icon" customClassName="nav-icon" :icon="item.attributes.icon"/> {{item.attributes.name}}

                <CBadge v-if="item.attributes.badge !== null" class="ms-auto" :color="item.attributes.badgeType">{{item.attributes.badge}}</CBadge>
              </CNavItem>
            </template>
          </template>
      </template>
    </CSidebarNav>
    <CSidebarFooter class="border-top">
      <CSidebarToggler/>
    </CSidebarFooter>
  </CSidebar>
</template>

<script>
import {
  CSidebar,
  CSidebarBrand,
  CSidebarFooter,
  CSidebarHeader,
  CSidebarNav, CSidebarToggler
} from "@coreui/vue-pro";

import {CNavGroup, CNavItem, CNavTitle} from "@coreui/vue-pro";
import {CBadge} from "@coreui/vue-pro";
import { useSidebarStore } from '@/stores/sidebar.js'
import {CNavLink} from "@coreui/vue-pro/dist/esm/components/nav";
import router from "../router";
import {CIcon} from "@coreui/icons-vue";
import {CLink} from "@coreui/vue-pro/dist/esm/components/link";

export default {
  setup()
  {
    return {

    }
  },
  data()
  {
    return {
      sidebar: useSidebarStore(),
      items: []
    }
  },

  mounted()
  {
    this.$root.$fabric.dashboard.fetchMenu().then(response =>
    {
      this.items = response.items;
    })

      // this.$root.$fabric.resources.save('holidays', new FabricResource({'name': 'Test'}));
  },

  methods:
    {
      navigate(item)
      {
        console.log(item.attributes.route);

        router.push(item.attributes.route);
      }
    },

  components: {
    CLink,
    CIcon,
    CNavLink,
    CSidebarToggler,
    CSidebarFooter,
    CNavGroup, CBadge, CNavItem, CSidebarNav, CNavTitle, CSidebarBrand, CSidebarHeader, CSidebar
  }
}
</script>
