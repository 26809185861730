<template>
  <div class="m-4">
    <div class="flex gap-[20px] flex-col">
      <h4>
        <span>Hits</span>
      </h4>

      <CollectionFilters :builder="filters" v-model="searchFilters" />

      <Collection :endpoint="this.$root.$fabric.config.apiURL + 'hits?instance_id='+this.$route.params.instanceId"
                  :filters="searchFilters"
                  order-by="-id"
                  :table="HitTableConfiguration"></Collection>
    </div>
  </div>
</template>

<script>
import {CCol, CContainer, CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CChart} from "@coreui/vue-chartjs";
import {CWidgetStatsA, CWidgetStatsB} from "@coreui/vue-pro/dist/esm/components/widgets";
import {CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle} from "@coreui/vue-pro/dist/esm/components/dropdown";
import Collection from "@/fabric/ui/Collection.vue";
import {TableBuilder} from "@/fabric/UI";
import {FilterBuilder} from "@/fabric/ui/FilterBuilder";
import ResourceListFilters from "@/fabric/dashboard/resources/ResourceListFilters.vue";
import CollectionFilters from "@/views/components/CollectionFilters.vue";
import VuePureLightbox from 'vue-pure-lightbox'

export default {
  computed: {
    filters()
    {
    let filters = new FilterBuilder();

      filters.addTextFilter('keyword', 'Keyword');

      return filters;
    },

    HitTableConfiguration()
    {
      let tableBuilder = new TableBuilder();

      // @ts-ignore
      tableBuilder
        .addColumn('name', 'Name')
        .setSize('w-[600px]')
        .usingContentCallback(function (entry)
        {
          if (entry.meta.url)
          {
            return `<a href="${entry.meta.url}" target="_blank">${entry.name}</a>`;
          }

          return entry.name;
        });

      tableBuilder
        .addColumn('image', 'Image')
        .setSize('w-[250px]')
        .usingContentCallback(function (entry)
        {
          if (entry.meta.image)
          {
            return `<a data-lightbox="${entry.id}" href="${entry.image}" target="_blank">
<img src="${entry.image}" class="max-w-[150px]">
</a>`;
          }

          return '-';
        });

      tableBuilder
        .addColumn('meta', 'Meta')
        .setSize('w-[400px]')
        .usingContentCallback(function (entry)
        {
          let meta = "";

          for (let key in entry.meta)
          {
            if (key == 'url' || key == 'image')
            {
              continue;
            }

            meta += `${key}: ${entry.meta[key]} <br />`;
          }

          return meta;
        });

      tableBuilder
        .addColumn('date', 'Date')
        .setSize('w-[200px]')
        .usingContentCallback(function (entry)
        {
          return entry.created_at;
        });


      return tableBuilder;
    },
  },
  components: {
    CollectionFilters,
    ResourceListFilters,
    Collection,
    CWidgetStatsB,
    VuePureLightbox,
    CDropdownItem, CDropdownMenu, CDropdownToggle, CDropdown, CWidgetStatsA, CChart, CCol, CRow, CContainer},

  data()
  {
    return {
      searchFilters: {
        keyword: '',
        minimum_rarity: 0,
      }
    }
  },

  mounted()
  {

  }
}
</script>
