<template>
  <div class="flex align-items-center">
    <a :href="entry.url" v-if="entry.url" class="mr-[10px] hidden md:inline-block" target="_blank">
      <img :src="entry.icon" class="max-w-[24px]">
    </a>

    <template v-else-if="entry.icon">
      <img :src="entry.icon" class="mr-[10px] hidden md:inline-block max-w-[24px]">
    </template>

    <span :class="{'bold cursor-pointer mr-[10px]': true}" @click.prevent="navigateTo">
      {{entry.name ? entry.name : 'Unnamed Instance' }}
    </span>
  </div>
</template>

<script>
import {CTooltip} from "@coreui/vue-pro/dist/esm/components/tooltip";
import {ref} from "vue";

export default {
  components: {CTooltip},
  props: {
    entry: {
      type: Object,
      required: true
    }
  },

  methods: {
    navigateTo()
    {
      this.$router.push({name: 'Instance Editor', params: {id: this.entry.id}})
    }
  }
}
</script>
